<template>
  <div class="container-download">
    <div class="download-wrap">
      <div class="left">
        <div
          class="download-item"
          v-for="(item, index) in downloadListLeft"
          :key="index"
        >
          <div class="down-left">
            <img :src="item.icon" alt="" />
            <div class="name">{{ item.name }}</div>
          </div>
          <div class="down-btn">
            <img
              src="https://imgw.pospal.cn/LandingPage/download-center/images/icons/i-1.png"
            /><a :href="item.link">立即下载</a>
          </div>
        </div>
      </div>
      <div class="right">
        <div
          class="download-item"
          v-for="(item, index) in downloadListRight"
          :key="index"
        >
          <div class="down-left">
            <img :src="item.icon" alt="" />
            <div class="name">{{ item.name }}</div>
          </div>
          <div class="down-btn">
            <img
              src="https://imgw.pospal.cn/LandingPage/download-center/images/icons/i-1.png"
            /><a :href="item.link">立即下载</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
  
<script>
export default {
  data() {
    return {
      downloadListLeft: [
        {
          icon: require("../../assets/logo.png"),
          name: "掌银零售版",
          link: "https://cdn.biot-apps.com/resource/APP221228a649a0f2wlzzv5gypg8wjbuxr/rec240826qneqfgbcch13tp6xgiugb4asb.apk?downloadName=zy_pos_V1.9.0_b20240826234154.apk", //v1.9.0
        },
      ],
      downloadListRight: [
        {
          icon: require("../../assets/fb_logo.png"),
          name: "掌银餐饮版（POS桌面端）",
          link: "https://cdn.biot-apps.com/resource/APP221228a649a0f2wlzzv5gypg8wjbuxr/rec241118htkimiylrxu75fe0dbqmgj046.apk?downloadName=restaurant_desktop_V2.5.0_b20241118105443.apk",
        },
        {
          icon: require("../../assets/fb_logo.png"),
          name: "掌银餐饮版（POS手持端）",
          link: "https://cdn.biot-apps.com/resource/APP221228a649a0f2wlzzv5gypg8wjbuxr/rec241118ypuhjxn832spiy78kgwdkjyg9.apk?downloadName=restaurant_mobile_V1.5.0_b20241118104645.apk",
        },
        {
          icon: require("../../assets/fb_logo.png"),
          name: "掌银餐饮版（TV端）",
          link: "https://cdn.biot-apps.com/resource/APP221228a649a0f2wlzzv5gypg8wjbuxr/rec2411187tze9mye6974si1flt7vqugfk.apk?downloadName=restaurant_tv_V1.0.0_b20241118105719.apk",
        },
      ],
    };
  },
};
</script>
  
<style lang="scss" scoped>
.container-download {
  height: 100%;
}
.download-wrap {
  box-sizing: border-box;
  flex: 1;
  display: flex;
  padding: 0 20px;
  padding-top: 110px;
  
  .left,
  .right {
    width: 50%;
  }
  .left{
    margin-right: 20px;
  }
  .download-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    // width: 40%;
    background: #f7f8fa;
    border-radius: 8px;
    padding: 20px;
    // margin-right: 20px;
    margin-bottom: 20px;
    height: 90px;
    .down-left {
      display: flex;
      align-items: center;
      img {
        width: 50px;
        height: 50px;
      }
      .name {
        font-size: 20px;
        font-weight: bold;
        margin-left: 20px;
      }
    }
    .down-btn {
      height: 40px;
      box-sizing: border-box;
      background: #ffffff;
      border: 1px solid #ff6626;
      border-radius: 4px;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      padding: 8px 20px;
      img {
        width: 16px;
        height: 16px;
        margin-right: 8px;
      }
      a {
        text-decoration: none;
        color: #333;
        font-size: 14px;
      }
      &:hover {
        background: #fff0e9;
      }
    }
  }
}
// .download-wrap {
//   box-sizing: border-box;
//   flex: 1;
//   display: flex;
//   flex-wrap: wrap;
//   padding: 0 20px;
//   padding-top: 110px;
//   justify-content: space-between;
//   .download-item {
//     display: flex;
//     align-items: center;
//     justify-content: space-between;
//     width: 40%;
//     background: #f7f8fa;
//     border-radius: 8px;
//     padding: 20px;
//     margin-right: 20px;
//     margin-bottom: 20px;
//     height: 90px;
//     .down-left {
//       display: flex;
//       align-items: center;
//       img {
//         width: 50px;
//         height: 50px;
//       }
//       .name {
//         font-size: 20px;
//         font-weight: bold;
//         margin-left: 20px;
//       }
//     }
//     .down-btn {
//       height: 40px;
//       box-sizing: border-box;
//       background: #ffffff;
//       border: 1px solid #ff6626;
//       border-radius: 4px;
//       display: flex;
//       justify-content: center;
//       align-items: center;
//       cursor: pointer;
//       padding: 8px 20px;
//       img {
//         width: 16px;
//         height: 16px;
//         margin-right: 8px;
//       }
//       a {
//         text-decoration: none;
//         color: #333;
//         font-size: 14px;
//       }
//       &:hover {
//         background: #fff0e9;
//       }
//     }
//   }
// }
</style>