<template>
  <div class="retail-wrap">
    <div class="p1">
      <div class="p1-left">
        <img src="./img/p2.png" />
      </div>
      <div class="p1-right">
        <div class="p1-right-title">智慧零售解决方案</div>
        <div class="p1-right-desc">
          <p>多业态、多场景、多终端、多渠道</p>
          <p>更智能、更灵活、更简单</p>
        </div>
        <div class="btn">
          <a
            href="https://zy_mgtv-s33hh8.biot-apps.com/"
            target="_blank"
            >立即体验 →</a
          >
        </div>
      </div>
    </div>
    <div class="p2">
      <div class="p2-title">适合零售多业态经营场景和模式</div>
      <img src="./img/p1.png" />
    </div>
    <div class="p3">
      <div class="p1-right p3-right">
        <div class="p1-right-title">多渠道收款</div>
        <div class="p1-right-desc">
          <p>
            支持微信、支付宝、云闪付、会员储值、会员积分、现金、记账、自定义等多种支付方式
          </p>
        </div>
      </div>
      <div class="p3-img">
        <img src="./img/p5.jpg" />
      </div>
    </div>
    <div class="p4">
      <div class="p4-img">
        <img src="./img/p3.png" />
      </div>
      <div class="p1-right">
        <div class="p1-right-title">数据驱动灵活决策</div>
        <div class="p1-right-desc">
          <p>实时掌握经营数据，及时调整策略</p>
          <p>让生意持续增长</p>
        </div>
      </div>
    </div>
    <div class="p5">
      <div class="p1-right p5-right">
        <div class="p1-right-title">门店管理更灵活、简单</div>
        <div class="p1-right-desc">
          <p>商品管理、库存管理、会员管理、营销管理、订单管理...</p>
        </div>
      </div>
      <div class="p5-img">
        <img src="./img/p4.png" />
      </div>
    </div>
    <div class="p6">
      <div class="p2-title">零售门店数字化解决方案</div>
      <img src="./img/p6.png" />
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.retail-wrap {
  width: 100%;
  padding: 0 20px;
  padding-top: 100px;
  box-sizing: border-box;
  // padding-bottom: 230px;
  .p1,
  .p3,
  .p4,
  .p5 {
    width: 100%;
    display: flex;
    align-items: center;
    margin-bottom: 120px;
    .p1-left,
    .p3-img,
    .p4-img,
    .p5-img {
      width: 60%;
      img {
        width: 100%;
      }
    }

    .p1-right {
      width: 40%;
      margin-left: 40px;
      position: relative;
      // z-index: -1;
      .p1-right-title {
        color: #ea6e23;
        font-size: 40px;
      }
      .p1-right-desc {
        font-size: 24px;
      }
      .btn {
        position: absolute;
        padding: 20px 40px;
        background: #ea6e23;
        font-size: 20px;
        border-radius: 40px;
        cursor: pointer;
        a {
          color: #fff;
          text-decoration: none;
        }
      }
    }
    .p3-right,
    .p5-right {
      margin-right: 40px;
    }
  }
  .p2,
  .p6 {
    width: 100%;
    margin-bottom: 120px;
    .p2-title {
      font-size: 40px;
      text-align: center;
      color: #ea6e23;
      padding-bottom: 24px;
    }
    img {
      width: 100%;
    }
  }
}
</style>